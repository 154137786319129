import http from "./http";

const parametros = {
  getYears: () => http.get("/sistemas/anos"),
  getMonths: () => http.get("/sistemas/meses"),
  getTipoDeCalculo: filters =>
    http.get("/campanhas/tipo-calculo", {
      params: { ...filters }
    }),
  getMetasFuncoes: filters =>
    http.get("/comissoes/meta-funcao", {
      params: { ...filters }
    }),

  putMetaFuncao: (id, data) => http.put(`/comissoes/meta-funcao/${id}`, data),
  postMetaFuncao: data => http.post("/comissoes/meta-funcao/", data),

  //Indicadores
  getIndicadores: filters => {
    return http.get("/sistemas/indicadores", { params: { ...filters } });
  },
  postIndicadores: data => {
    return http.post("/sistemas/indicadores", data);
  },
  putIndicadores: (id, data) => {
    return http.put(`/sistemas/indicadores/${id}`, data);
  },
  deleteIndicadores: id => {
    return http.get(`/sistemas/indicadores/${id}`);
  },

  // Parâmetros por datas
  getParametroData: filters => {
    return http.get("/comissoes/parametros-datas", { params: { ...filters } });
  },

  postParametroData: data => {
    return http.post("/comissoes/parametros-datas", data);
  },

  putParametroData: (id, data) => {
    return http.put(`/comissoes/parametros-datas/${id}`, data);
  },

  deleteParametroData: id => {
    return http.delete(`/comissoes/parametros-datas/${id}`);
  },
  // Parâmetros por datas

  // Valor Fixo
  getValorFixo: filters => {
    return http.get("/campanhas/tipo-meta-bonus", { params: { ...filters } });
  },

  postValorFixo: data => {
    return http.post("/campanhas/tipo-meta-bonus", data);
  },

  deleteValorFixo: id => {
    return http.delete(`/campanhas/tipo-meta-bonus/${id}`);
  },

  putValorFixo: (id, data) => {
    return http.put(`/campanhas/tipo-meta-bonus/${id}`, data);
  },
  // Valor Fixo

  // Produto Modelo
  getProdutoModelo: filters => {
    return http.get("/campanhas/produto-modelo", { params: { ...filters } });
  },

  deleteProdutoModelo: id => {
    return http.delete(`/campanhas/produto-modelo/${id}`);
  },
  // Produto Modelo

  // Meta Modelo Colaborador
  getMetaModeloColaborador: filters => {
    return http.get("/campanhas/meta-modelo-individual", {
      params: { ...filters }
    });
  },

  postMetaModeloColaborador: data => {
    return http.post("/campanhas/meta-modelo-individual", data);
  },

  deleteMetaModeloColaborador: id => {
    return http.delete(`/campanhas/meta-modelo-individual/${id}`);
  },
  // Meta Modelo Colaborador

  // Mete Modelo Progressiva
  getMetaModeloColaboradorProgressiva: filters => {
    return http.get("/campanhas/meta-progressiva-modelo", {
      params: { ...filters }
    });
  },

  postMetaModeloColaboradorProgressiva: data => {
    return http.post("/campanhas/meta-progressiva-modelo", data);
  },

  deleteMetaModeloColaboradorProgressiva: id => {
    return http.delete(`/campanhas/meta-progressiva-modelo/${id}`);
  },
  // Mete Modelo Progressiva

  // Tipo Indice
  getTipoIndice: filters => {
    return http.get("/campanhas/tipo-indice", {
      params: { ...filters }
    });
  },
  // Tipo Indice

  // Tipo Meta Pagamento
  getMetaTipoPagamento: filters => {
    return http.get("/campanhas/tipo-meta-pagamento", {
      params: { ...filters }
    });
  },

  postTipoMetaPagamento: data => {
    return http.post("/campanhas/tipo-meta-pagamento", data);
  },

  putTipoMetaPagamento: (id, data) => {
    return http.put(`/campanhas/tipo-meta-pagamento/${id}`, data);
  },
  // Tipo Meta Pagamento

  // Tipo Pagamento
  getTipoPagamento: filters => {
    return http.get("/campanhas/tipo-pagamento", {
      params: { ...filters }
    });
  },

  postTipoPagamento: data => {
    return http.post("/campanhas/tipo-pagamento", data);
  },

  putTipoPagamento: (id, data) => {
    return http.put(`/campanhas/tipo-pagamento/${id}`, data);
  },

  // Tipo Pagamento

  // Tipo Meta
  getTipoMetas: filters =>
    http.get("/campanhas/tipo-meta", {
      params: { ...filters }
    }),

  postTipoMetas: data => {
    return http.post("/campanhas/tipo-meta", data);
  },

  putTipoMetas: (id, data) => {
    return http.put(`/campanhas/tipo-meta/${id}`, data);
  },
  // Tipo Meta

  // Colaborador Campanha Progressiva
  getColaboradorCampanhaProgressiva: filters =>
    http.get("/campanhas/colaborador-progressiva", {
      params: { ...filters }
    }),

  postColaboradorCampanhaProgressiva: data => {
    return http.post("/campanhas/colaborador-progressiva", data);
  },

  deleteColaboradorCampanhaProgressiva: id => {
    return http.delete(`/campanhas/colaborador-progressiva/${id}`);
  }
  // Colaborador Campanha Progressiva
};

export default parametros;
