import http from "./http";

const colaborador = {
  //Colaboradores
  getColaboradores: filters => {
    return http.get("/sistemas/colaboradores", { params: { ...filters } });
  },

  createColaborador(data) {
    return http.post(`/sistemas/colaboradores`, data);
  },

  editColaborador(id_colaborador, data) {
    return http.put(`/sistemas/colaboradores/${id_colaborador}`, data);
  },

  deleteColaborador: id => {
    return http.delete(`/sistemas/colaboradores/${id}`);
  },

  //Funcao Nivel
  getFuncaoNivel: filters => {
    return http.get("/sistemas/funcao-nivel", { params: { ...filters } });
  },

  postFuncaoNivel(data) {
    return http.post(`/sistemas/funcao-nivel`, data);
  },

  putFuncaoNivel(id_colaborador, data) {
    return http.put(`/sistemas/funcao-nivel/${id_colaborador}`, data);
  },

  deleteFuncaoNivel: id => {
    return http.delete(`/sistemas/funcao-nivel/${id}`);
  },

  //Colaborador Empresa
  getColaboradoresEmpresa: filters => {
    return http.get("/sistemas/colaboradores-empresas", {
      params: { ...filters }
    });
  },

  getColaboradoresEquipe: filters => {
    return http.get("/comissoes/colaborador-equipe", {
      params: { ...filters }
    });
  },

  postColaboradoresEquipe: data => {
    return http.post("/comissoes/colaborador-equipe", data);
  },

  putColaboradoresEquipe: (id, data) => {
    return http.put(`/comissoes/colaborador-equipe/${id}`, data);
  },

  //Colaboradores Empresas
  getColabEmpresas: filters => {
    return http.get("/sistemas/colaboradores-empresas", {
      params: { ...filters }
    });
  },
  postColabEmpresas: data => {
    return http.post("/sistemas/colaboradores-empresas", data);
  },
  putColabEmpresas: (id, data) => {
    return http.put(`/sistemas/colaboradores-empresas/${id}`, data);
  },
  deleteColabEmpresas: id => {
    return http.get(`/sistemas/colaboradores-empresas/${id}`);
  }
};

export default colaborador;
