import empresaService from "./empresa";
import tipoMetaService from "./tipoMeta";
import comissaoService from "./comissao";
import parametrosService from "./parametros";
import procedureService from "./procedures";
import campanhaService from "./campanha";
import colaboradorService from "./colaborador";
import sistemaService from "./sistema";
// import statusCampanha from "./statusCampanha";

const filter = {
  campanhas: filter => campanhaService.getAll(filter),
  empresa: filter => empresaService.getEmpresas(filter),
  bandeira: filter => empresaService.getBandeiras(filter),
  funcao: filter => empresaService.getFuncoes(filter),
  colaborador: filter => colaboradorService.getColaboradores(filter),
  tipoEvento: filter => comissaoService.getTiposEvento(filter),
  tipoDado: filter => comissaoService.getTiposDados(filter),
  tipoMeta: filter => tipoMetaService.getTipoMeta(filter),
  publico: filter => campanhaService.getPublicoAlvo(filter),
  indicadores: filter => campanhaService.getIndicadores(filter),
  anos: filter => parametrosService.getYears(filter),
  meses: filter => parametrosService.getMonths(filter),
  proc: filter => procedureService.getProc(filter),
  processos: filter => procedureService.getProcessos(filter),
  usuarios: filter => sistemaService.getUsers(filter),
  modulos: filter => sistemaService.getModulo(filter),
  menus: filter => sistemaService.getMenu(filter),
  niveis: filter => sistemaService.getNiveis(filter),
  papeis: filter => sistemaService.getPapeis(filter),
  statusCampanha: filter => campanhaService.getStatusCampanha(filter)
};

export default filter;
